import request from '@/utils/request'

const getBlocs = () => {
  return request({
    url: '/open/blocs',
    method: 'get'
  })
}
const getSchool = (params) => {
  return request({
    url: '/open/schools',
    params: params,
    method: 'get'
  })
}

export {
  getBlocs, // 获取联盟列表
  getSchool // 获取学校列表
}
