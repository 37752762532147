import request from '@/utils/request'

const addMyVip = (schoolId, params) => {
  return request({
    url: `/mg/schools/${schoolId}/users`,
    data: params,
    method: 'post'
  })
}

const getVipList = (schoolId, params) => {
  return request({
    url: `/mg/schools/${schoolId}/users`,
    params,
    method: 'get'
  })
}

const getVipListLength = (schoolId, params) => {
  return request({
    url: `/mg/schools/${schoolId}/users/count`,
    params,
    method: 'get'
  })
}

const deleteVip = (schoolId, id) => {
  return request({
    url: `/mg/schools/${schoolId}/users/${id}`,
    method: 'delete'
  })
}

const modifyVip = (schoolId, id, params) => {
  return request({
    url: `/mg/schools/${schoolId}/users/${id}`,
    data: params,
    method: 'put'
  })
}

const getVipInfo = (schoolId, id) => {
  return request({
    url: `/mg/schools/${schoolId}/users/${id}`,
    mothod: 'get'
  })
}

const importUser = (schoolId, params) => {
  return request({
    url: `/mg/schools/${schoolId}/users/import`,
    data: params,
    method: 'post'
  })
}
const importUserHeaders = (schoolId, params) => {
  return request({
    url: `/mg/schools/${schoolId}/users/import/headers`,
    data: params,
    method: 'post'
  })
}

// 获取超管用户列表
const getSupertubeList = (params) => {
  return request({
    url: `/mg/admins`,
    params,
    method: 'get'
  })
}

// 获取超管列表超管数量
const getSupertubeLength = (params) => {
  return request({
    url: `/mg/admins/count`,
    params,
    method: 'get'
  })
}
// 创建超管用户
const createSupertuber = (params) => {
  return request({
    url: `/mg/admins`,
    data: params,
    method: 'post'
  })
}
// 移除超管用户
const deleteSupertuber = (userId) => {
  return request({
    url: `/mg/admins/${userId}`,
    method: 'delete'
  })
}

// 获取分管列表数量
const getChargeLength = (schoolId, params) => {
  return request({
    url: `/mg/schools/${schoolId}/manages/count`,
    params,
    method: 'get'
  })
}

//  获取分管用户列表
const getChargeList = (schoolId, params) => {
  return request({
    url: `/mg/schools/${schoolId}/manages`,
    params,
    method: 'get'
  })
}

// 创建分管用户
const createCharger = (schoolId, params) => {
  return request({
    url: `/mg/schools/${schoolId}/manages`,
    data: params,
    method: 'post'
  })
}

// 移除分管用户
const deleteCharger = (schoolId, userId) => {
  return request({
    url: `/mg/schools/${schoolId}/manages/${userId}`,
    method: 'delete'
  })
}
// 获取店铺列表
const getStoreList = (storeId, params) => {
  return request({
    url: `/mg/stores/${storeId}/uesrs`,
    params,
    method: 'get'
  })
}

// 获取店铺列表数量
const getStoreLength = (storeId, params) => {
  return request({
    url: `/mg/stores/${storeId}/uesrs/count`,
    params,
    method: 'get'
  })
}

// 创建店铺
const createStore = (params) => {
  return request({
    url: `/mg/stores/0/uesrs`,
    data: params,
    method: 'post'
  })
}
// 移除店铺用户
const deleteStore = (storeId, userId) => {
  return request({
    url: `/mg/stores/${storeId}/uesrs/${userId}`,
    method: 'delete'
  })
}
export {
  addMyVip,
  getVipList,
  getVipListLength,
  deleteVip,
  modifyVip, // 修改会员信息
  getVipInfo, // 获取用户信息
  importUser, // 上传用户数据，excel
  importUserHeaders, // 上传用户头像 zip
  getSupertubeList,
  getSupertubeLength,
  createSupertuber,
  deleteSupertuber,
  getChargeLength,
  getChargeList,
  createCharger,
  deleteCharger,
  getStoreList,
  getStoreLength,
  createStore,
  deleteStore
}
