<template>
  <div class="wrapper" v-if="isFlag">
    <a-card title="高级搜索" style="width: 100%">
      <a slot="extra" href="#" @click="putAway">收起↑</a>
      <div class="searchSenior">
        <div class="searchTerms">
          <span class="title">学校筛选:</span>
          <a-select show-search optionFilterProp="title" style="width:220px" v-model="schoolVal" @change="handleChangeSch">
            <a-select-option :value="item.id" :title="item.name" v-for="(item, index) in schoolList" :key="index">
              {{ item.name }}
            </a-select-option>
          </a-select>
        </div>
        <template v-if="isToS || isOrder">
          <div class="searchTerms">
            <span class="title">部门筛选:</span>
            <a-select show-search optionFilterProp="title" style="width:220px" v-model="depName" @select="handleChangeDep">
              <a-select-option :value="item.id" :title="item.text" v-for="(item, index) in depNames" :key="index">
                {{ item.text }}
              </a-select-option>
            </a-select>
          </div>
          <div class="searchTerms">
            <span class="title">职务筛选:</span>
            <a-select show-search optionFilterProp="title" style="width:220px" v-model="dutyName" @change="handleChangeDuty"> 
              <a-select-option :value="item.id" :title="item.text" v-for="(item, index) in dutyNames" :key="index">
                {{ item.text }}
              </a-select-option>
            </a-select>
          </div>
          <div class="searchTerms">
            <span class="title">职位筛选:</span>
            <a-select show-search optionFilterProp="title" style="width:220px" v-model="positionName" @change="handleChangePos">
              <a-select-option :value="item.id" :title="item.text" v-for="(item, index) in positionNames" :key="index">
                {{ item.text }}
              </a-select-option>
            </a-select>
          </div>
        </template>
        <template v-else>
          <div class="searchTerms">
            <span class="title">班级筛选:</span>
            <a-input placeholder="请输入班级" v-model="team" style="width:220px" @change="changeTeam" />
          </div>
          <div class="searchTerms">
            <span class="title">入学年份筛选:</span>
            <a-select show-search optionFilterProp="title" style="width:220px" v-model="intoYear" @change="handleChangeYar" placeholder="请选择入学年份">
              <a-select-option :value="item.id" :title="item.text" v-for="(item, index) in entranceYear" :key="index">
                {{ item.text }}
              </a-select-option>
            </a-select>
          </div>
        </template>
        <template v-if="isOrder">
          <div class="searchTerms">
            <span class="title">订单状态筛选:</span>
            <a-select
              style="width:220px"
              allowClear
              :value="orderStatusValue"
              @change="handleChangeStatus"
              mode="multiple"
              placeholder="请选择订单状态"
              :maxTagCount="2"
              option-label-prop="label"
            >
              <a-select-option :value="item.id" :label="item.text" v-for="(item, index) in orderStatus" :key="index">
                {{ item.text }}
              </a-select-option>
            </a-select>
          </div>
          <div class="searchTerms">
            <span class="title">支付方式筛选:</span>
            <a-select style="width:220px" v-model="paymethod" @select="handleChangePay">
              <a-select-option :value="item.id" v-for="(item, index) in payMethods" :key="index">
                {{ item.text }}
              </a-select-option>
            </a-select>
          </div>
          <div class="searchTerms">
            <span class="title">下单日期筛选:</span>
            <a-range-picker
              :show-time="{ format: 'HH:mm' }"
              format="YYYY-MM-DD HH:mm"
              :placeholder="['开始时间', '结束时间']"
              @change="onChange"
              :value="createValue"
            />
          </div>
          <div class="searchTerms">
            <span class="title">退货筛选:</span>
            <a-select style="width:220px" v-model="returns" @select="handleRefundOrder">
              <a-select-option :value="item.id" v-for="(item, index) in refundOrder" :key="index">
                {{ item.text }}
              </a-select-option>
            </a-select>
          </div>
        </template>
      </div>
      <a-button type="default" style="margin-right:20px;margin-top:20px;margin-left:30px" @click="clearEmpty">清空</a-button>
      <a-button type="primary" @click="goSearch">确认</a-button>
    </a-card>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    isFlag: {
      type: Boolean
    },
    isToS: {
      type: Boolean,
      default: true
    },
    isOrder: {
      type: Boolean
    },
    schoolList: {
      type: Array
    },
    entranceYear: {
      type: Array
    },
    depNames: {
      type: Array
    },
    dutyNames: {
      type: Array
    },
    positionNames: {
      type: Array
    },
    orderStatus: {
      type: Array
    },
    payMethods: {
      type: Array
    },
    refundOrder: {
      type: Array
    }
  },
  data() {
    return {
      schoolVal: "a",
      depName: "", // 部门
      dutyName: "", // 职务
      positionName: "", // 职位
      intoYear: "", // 入学年份
      team: "", // 班级
      orderstatus: [], // 订单状态
      orderStatusValue: [],
      paymethod: "", // 支付方式
      startTime: "",
      returns: "",
      endTime: "",
      createValue: []
    };
  },
  methods: {
    onChange(value, dateString) {
      console.log("Formatted Selected Time: ", dateString);
      this.createValue = value;
      this.startTime = dateString[0];
      this.endTime = dateString[1];
      this.$emit("changeField", "startDate", this.startTime);
      this.$emit("changeField", "endDate", this.endTime);
    },
    putAway() {
      this.$emit("putAway");
    },
    goSearch() {
      let schoolId = this.schoolVal;
      if (this.isToS) {
        let params = {
          page: 0,
          rows: 10,
          depName: this.depName,
          dutyName: this.dutyName,
          positionName: this.positionName,
          type: "TEACHER"
        };
        this.$emit("seniorSearch", params, schoolId);
      } else if (this.isOrder) {
        let params = {
          page: 0,
          rows: 20,
          keyword: "",
          returns: this.returns ? true : "",
          returnsAudit: this.getReturnAudit(this.returns),
          state: this.orderstatus,
          sort: "create_time desc",
          startDate: this.startTime,
          endDate: this.endTime,
          payTypeCode: this.paymethod,
          depName: this.depName,
          dutyName: this.dutyName,
          positionName: this.positionName,
          schoolId,
          showNoPay: false
        };
        this.$emit("seniorSearch", params);
      } else {
        let params = {
          page: 0,
          rows: 10,
          team: this.team,
          intoYear: this.intoYear,
          type: "STUDENT"
        };
        this.$emit("seniorSearch", params, schoolId);
      }
    },
    clearEmpty() {
      this.schoolVal = "a";
      let schoolId = "a";
      if (this.isToS) {
        this.depName = "";
        this.dutyName = "";
        this.positionName = "";
        let params = {
          page: 0,
          rows: 10,
          depName: "",
          dutyName: "",
          positionName: "",
          type: "TEACHER"
        };
        this.$emit("clear", params, schoolId);
      } else if (this.isOrder) {
        this.depName = "";
        this.dutyName = "";
        this.positionName = "";
        this.returns = "";
        this.orderstatus = [];
        this.orderStatusValue = [];
        this.paymethod = "";
        this.startTime = this.endTime = "";
        this.createValue = [];
        let params = {
          page: 0,
          rows: 20,
          keyword: "",
          state: "",
          sort: "create_time desc",
          startDate: "",
          endDate: "",
          payTypeCode: "",
          depName: "",
          dutyName: "",
          returns: "",
          returnsAudit: "",
          positionName: "",
          schoolId: "a",
          showNoPay: false
        };
        this.$emit("clear", params);
      } else {
        this.intoYear = "";
        this.team = "";
        let params = {
          page: 0,
          rows: 10,
          team: "",
          intoYear: "",
          type: "STUDENT"
        };
        this.$emit("clear", params, schoolId);
      }
    },
    changeTeam(val) {
      this.$emit("changeField", "team", this.team);
    },
    handleChangeSch(val) {
      this.schoolVal = val;
      this.$emit("changeField", "schoolId", val);
    },
    handleChangeYar(val) {
      this.intoYear = val;
      this.$emit("changeField", "intoYear", val);
    },
    handleChangeDep(val) {
      this.depName = val;
      this.$emit("changeField", "depName", val);
    },
    handleChangeDuty(val) {
      this.dutyName = val;
      this.$emit("changeField", "dutyName", val);
    },
    handleChangePos(val) {
      this.positionName = val;
      this.$emit("changeField", "positionName", val);
    },
    handleChangeStatus(val) {
      this.orderStatusValue = val;
      val = val.join(",");
      this.orderstatus = val;
      this.$emit("changeField", "state", val);
    },
    handleChangePay(val) {
      this.paymethod = val;
      this.$emit("changeField", "payTypeCode", val);
    },
    handleRefundOrder(val) {
      console.log("val", val);
      this.returns = val;
      this.$emit("changeField", "returns", true);
      this.$emit("changeField", "returnsAudit", this.getReturnAudit(val));
    },
    getReturnAudit(id) {
      switch (id) {
        case 1:
          return "";
        case 2:
          return 0;
        case 3:
          return 100;
        case 4:
          return -100;
        default:
          return "";
      }
    }
  }
};
</script>
<style scoped lang="less">
.wrapper {
  padding: 20px;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 10px;
  .searchSenior {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    .searchTerms {
      margin: 0 10px 15px 0;
      display: flex;
      flex-direction: row;
      align-items: center;
      .title {
        margin-right: 10px;
      }
    }
  }
  /deep/ .ant-card-head {
    border-bottom: 0 !important;
  }
}
</style>
