import {
  getSchool
} from '@/serve/open'
import {
  getVipList,
  getVipListLength,
  deleteVip
} from '@/serve/member'
import global from 'global'
export const advancedScreen = {
  data () {
    return {
      keyword: '',
      schoolId: 'a',
      current: 1,
      total: 0,
      refundOrder: [], // 退款订单
      entranceYear: [],
      orderStatus: [], // 订单状态
      payMethods: [], // 支付方式
      isFlag: false, // 高级搜索开关
      depNames: [], // 部门
      dutyNames: [], // 职务
      positionNames: [], // 职位
      schoolList: [], // 学校列表
      params: {
        page: 0,
        rows: 10,
        type: '',
        depName: '',
        dutyName: '',
        positionName: '',
        team: '',
        intoYear: '',
        keyword: ''
      }
    }
  },
  created () {
    this.createDepartmentDep()
    this.createDepartmentDuty()
    this.createDepartmentPos()
    this.createPayMethod()
    this.createRefundOrder()
    this.createOrderStatus()
    this.getSchoolList() // 获取学校列表
    this.params.type = this.type
    // this.getList('a', this.params) // 获取会员列表
    this.creationYear() // 创建年份列表
  },
  methods: {
    // 创建订单状态列表
    createOrderStatus () {
      let orderStatus = ['全部', '新订单', '审核通过', '支付完成', '已发货', '收货', '完成', '关闭（取消）']
      let orderStatusArr = ['', 'NEW', 'SHOP_AUDIT', 'PAYMENT', 'DELIVERY', 'RECEIVED', 'FINISH', 'CANCEL']
      for (let i = 0; i < orderStatus.length; i++) {
        if (i === 0) {
          this.orderStatus.push({
            id: '',
            text: '全部'
          })
        } else {
          this.orderStatus.push({
            id: orderStatusArr[i],
            text: orderStatus[i]
          })
        }
      }
    },
    // 创建支付方式
    createPayMethod () {
      let payMethods = ['全部', '在线支付', '线下付款']
      let payMethodEn = ['', 'onlinePayment', 'deliveryPayment']
      for (let i = 0; i < payMethods.length; i++) {
        if (i === 0) {
          this.payMethods.push({
            id: '',
            text: '全部'
          })
        } else {
          this.payMethods.push({
            id: payMethodEn[i],
            text: payMethods[i]
          })
        }
      }
    },
    createRefundOrder () {
      let refundOrder = ['全部退货订单', '退货申请中', '退货成功', '退货失败']
      let refundState = [1, 2, 3, 4]
      for (let i = 0; i < refundOrder.length; i++) {
        this.refundOrder.push({
          id: refundState[i],
          text: refundOrder[i]
        })
      }
    },
    // 入学年份
    formatYear (year) {
      if (year) {
        let index = year.indexOf('-')
        if (index) {
          return year.slice(0, index)
        } else {
          return year
        }
      }
    },
    // 年份列表
    creationYear () {
      let temp = [...new Array(new Date().getFullYear() + 2).keys()].slice(
        new Date().getFullYear() - 20
      );
      temp.reverse().forEach(item => {
        this.entranceYear.push({
          id: item,
          text: item
        });
      });
    },
    schoolChange (val) {
      this.schoolVal = val
      let temp = val.split(',')
      this.selectSchoolId = temp[0]
      this.selectSchoolName = temp[1]
      if (this.fileName.split('.')[0] !== temp[1]) {
        this.isDisabled = true
      } else {
        this.isDisabled = false
      }
    },
    typeChange (val) {
      this.type = val
    },
    async onSearch () {
      this.params.page = 0
      this.params.keyword = this.keyword
      this.getList('a', this.params)
    },
    async getList (schoolId, params) {
      this.loading = true
      this.total = await getVipListLength(schoolId, params)
      this.total > 0 ? (this.data = await getVipList(schoolId, params)) : (this.data = [])
      this.loading = false
    },
    // 高级搜索
    async getSearchList (params, id) {
      this.schoolId = id
      this.params = params
      this.current = 1
      this.getList(this.schoolId, this.params)
    },
    // 分页
    pageChange (page) {
      this.params.page = page - 1
      this.getList(this.schoolId, this.params) // 获取会员列表
    },
    DeletePro (schoolId, id) {
      let that = this
      this.$confirm({
        title: '提示',
        content: '真的要删除吗 ?',
        okText: '确定',
        cancelText: '取消',
        onOk () {
          deleteVip(schoolId, id)
            .then(() => {
              that.getList('a', that.params)
              that.$message.success('删除成功')
            })
            .catch(() => {
              that.$message.error('删除失败,请稍后再试。')
            })
        },
        onCancel () {}
      })
    },
    // 更改字段
    changeField (field, value) {
      if (field === 'schoolId') {
        this.schoolId = value
      } else {
        this.params[field] = value
      }
    },
    // 清空
    clear (params, id) {
      this.schoolId = id
      this.params = params
      this.current = 1
      this.getList(this.schoolId, this.params)
    },
    getPhoto (id) {
      if (!id) {
        return false
      } else if (id.indexOf(',') > -1) {
        let reg = new RegExp(',')
        let ids = id.replace(reg, '')
        return global.file.url + '/sys/fs/' + ids
      } else {
        return global.file.url + '/sys/fs/' + id
      }
    },
    // 获取学校列表
    getSchoolList () {
      getSchool().then(res => {
        this.schoolList = res
        this.schoolList.unshift({
          id: 'a',
          name: '全部'
        })
      })
    },
    // 创建部门列表
    createDepartmentDep () {
      let depNames = ['全部', '教学部', '管理团队', '教师团队', '后勤团队', '教务部']
      for (let i = 0; i < depNames.length; i++) {
        if (i === 0) {
          this.depNames.push({
            id: '',
            text: '全部'
          })
        } else {
          this.depNames.push({
            id: depNames[i],
            text: depNames[i]
          })
        }
      }
    },
    // 创建职位列表
    createDepartmentPos () {
      let positionNames = [
        '全部',
        '主要管理层',
        '主任',
        '财务',
        '总务',
        '保健',
        '中方教师',
        '外籍教师',
        '编辑及图书管理员',
        '保育员',
        '食堂',
        '保安',
        '校车人员',
        '保洁',
        '行政',
        '中籍教师',
        '图书管理员',
        '编辑',
        '杂工',
        '老师',
        '体育老师'
      ]
      for (let i = 0; i < positionNames.length; i++) {
        if (i === 0) {
          this.positionNames.push({
            id: '',
            text: '全部'
          })
        } else {
          this.positionNames.push({
            id: positionNames[i],
            text: positionNames[i]
          })
        }
      }
    },
    // 创建职务列表
    createDepartmentDuty () {
      let dutyNames = [
        '全部',
        '外籍教师',
        '园长',
        '外籍园长',
        '园长助理',
        '保教主任',
        '财务',
        '总务',
        '保健',
        '中籍教师',
        '图书管理员',
        '保育员',
        '食堂',
        '保安',
        '晚班门卫',
        '校车人员',
        '外清洁',
        '行政助理',
        '副园长',
        '编辑',
        '保洁',
        '课程主管',
        '杂工',
        '实习教师',
        '总园长',
        '老师'
      ]
      for (let i = 0; i < dutyNames.length; i++) {
        if (i === 0) {
          this.dutyNames.push({
            id: '',
            text: '全部'
          })
        } else {
          this.dutyNames.push({
            id: dutyNames[i],
            text: dutyNames[i]
          })
        }
      }
    },
    // 高级搜索
    senoirSearch () {
      this.isFlag = !this.isFlag
    },
    // 学校筛选
    formatSchool (val) {
      for (let i = 0; i < this.schoolList.length; i++) {
        let item = this.schoolList[i]
        if (item.id === val) {
          return item.name
        }
      }
      return ''
    }
  }
}
